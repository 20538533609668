// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type MutationStatus = "SUCCESS" | "FUTURE_VALUE";
export type MutationStatusKnownValue = "SUCCESS";
export const MutationStatusKnownValues: MutationStatusKnownValue[] = [ "SUCCESS" ];
export type WebhookCallStatus = "ATTEMPTING" | "SUCCEEDED" | "FAILED" | "FUTURE_VALUE";
export type WebhookCallStatusKnownValue = "ATTEMPTING" | "SUCCEEDED" | "FAILED";
export const WebhookCallStatusKnownValues: WebhookCallStatusKnownValue[] = [ "ATTEMPTING","SUCCEEDED","FAILED" ];
export type ApiMethod = "GET" | "PUT" | "POST" | "PATCH" | "DELETE" | "HEAD" | "OPTIONS" | "FUTURE_VALUE";
export type ApiMethodKnownValue = "GET" | "PUT" | "POST" | "PATCH" | "DELETE" | "HEAD" | "OPTIONS";
export const ApiMethodKnownValues: ApiMethodKnownValue[] = [ "GET","PUT","POST","PATCH","DELETE","HEAD","OPTIONS" ];



export type WebhookEventsFilter = { createdAtAfter?: Maybe<ISOString>; createdAtBefore?: Maybe<ISOString>; type?: Maybe<string> }
export type CreateWebhookInput = { url: string }
export type UpdateWebhookInput = { url: string }
export type CreateServerKeyInput = { name?: Maybe<string>; description?: Maybe<string> }
export type UpdateServerKeyInput = { name?: Maybe<string>; description?: Maybe<string> }
export type UpdateOrganizationInput = { apiVersion: string }
export type ApiLogsFilter = { createdAtAfter?: Maybe<ISOString>; createdAtBefore?: Maybe<ISOString>; path?: Maybe<string>; responseCode?: Maybe<Int>; method?: Maybe<ApiMethod> }

export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {};
export type PossibleTypes = {

};

export type EntityName = 
  | "Organization"
  | "SubOrganization"
  | "ServerKey"
  | "Webhook"
  | "WebhookEvent"
  | "WebhookCall"
  | "WebhookCallFailure"
  | "ApiLog"
export type EntityFieldMap = {
  Organization: "uuid" | "subOrganizationCreationEnabled" | "subOrganizations" | "alertEmails" | "pinnedVersion";
  SubOrganization: "uuid" | "displayName" | "createdAt";
  ServerKey: "uuid" | "publicId" | "name" | "description" | "createdAt";
  Webhook: "uuid" | "url" | "signatureSecret" | "createdAt";
  WebhookEvent: "uuid" | "type" | "createdAt" | "status" | "calls";
  WebhookCall: "uuid" | "createdAt" | "status" | "lastAttemptedAt" | "numFailures" | "nextAttemptAt" | "webhook" | "failures";
  WebhookCallFailure: "uuid" | "exceptionText" | "httpStatusCode" | "httpResponse" | "createdAt";
  ApiLog: "uuid" | "createdAt" | "path" | "method" | "responseCode" | "errorCode" | "errorMessage" | "ipAddress" | "apiKey" | "apiVersion"
}
export type QueryVariablesMap = { webhookEvent: "uuid"; webhookEvents: "page" | "cursor" | "numberOfItems" | "filters" | "createdAtAfter" | "createdAtBefore" | "type"; apiLog: "uuid"; apiLogs: "page" | "cursor" | "numberOfItems" | "filters" | "createdAtAfter" | "createdAtBefore" | "path" | "responseCode" | "method" }

export type QueryName = 
  | "GetWebhookEvent"
  | "GetWebhookEvents"
  | "GetWebhookSettings"
  | "GetWebhooks"
  | "GetAPIVersions"
  | "GetOrganizationAPIVersion"
  | "AllServerKeys"
  | "GetApiLogs"
  | "GetApiLogEndpoints"
  | "GetApiLogCodes"

const WebhookFragmentDocument = gql`
fragment Webhook on Webhook {
  uuid
  url
  signatureSecret
}

`
export const WebhookFragmentProps: Fragment<WebhookFragment> = {
  document: WebhookFragmentDocument,
  fragmentName: "Webhook",
  entityName: "Webhook",
  __do_not_use_Data: null
}
export type WebhookFragment = { __typename: "Webhook"; uuid: UUID; url: string; signatureSecret: string }

const WebhookCallFragmentDocument = gql`
fragment WebhookCall on WebhookCall {
  uuid
  createdAt
  status
  lastAttemptedAt
  numFailures
  nextAttemptAt
  webhook {
    ...Webhook
  }
  failures {
    uuid
    exceptionText
    httpStatusCode
    httpResponse
    createdAt
  }
}
${WebhookFragmentDocument}
`
export const WebhookCallFragmentProps: Fragment<WebhookCallFragment> = {
  document: WebhookCallFragmentDocument,
  fragmentName: "WebhookCall",
  entityName: "WebhookCall",
  __do_not_use_Data: null
}
export type WebhookCallFragment = {
  __typename: "WebhookCall";
  uuid: UUID;
  createdAt: ISOString;
  status: WebhookCallStatus;
  lastAttemptedAt: ISOString;
  numFailures: Int;
  nextAttemptAt: Maybe<ISOString>;
  webhook: WebhookFragment;
  failures: Array<{
  __typename: "WebhookCallFailure";
  uuid: UUID;
  exceptionText: Maybe<string>;
  httpStatusCode: Maybe<Int>;
  httpResponse: Maybe<string>;
  createdAt: ISOString
}>
}

const WebhookEventFragmentDocument = gql`
fragment WebhookEvent on WebhookEvent {
  uuid
  type
  createdAt
  status
}

`
export const WebhookEventFragmentProps: Fragment<WebhookEventFragment> = {
  document: WebhookEventFragmentDocument,
  fragmentName: "WebhookEvent",
  entityName: "WebhookEvent",
  __do_not_use_Data: null
}
export type WebhookEventFragment = { __typename: "WebhookEvent"; uuid: UUID; type: string; createdAt: ISOString; status: WebhookCallStatus }

const ServerKeyFragmentDocument = gql`
fragment ServerKey on ServerKey {
  uuid
  publicId
  name
  description
  createdAt
}

`
export const ServerKeyFragmentProps: Fragment<ServerKeyFragment> = {
  document: ServerKeyFragmentDocument,
  fragmentName: "ServerKey",
  entityName: "ServerKey",
  __do_not_use_Data: null
}
export type ServerKeyFragment = {
  __typename: "ServerKey";
  uuid: UUID;
  publicId: string;
  name: Maybe<string>;
  description: Maybe<string>;
  createdAt: ISOString
}

const ApiLogFragmentDocument = gql`
fragment ApiLog on ApiLog {
  uuid
  createdAt
  ipAddress
  path
  responseCode
  errorCode
  errorMessage
  apiKey
  apiVersion
  method
}

`
export const ApiLogFragmentProps: Fragment<ApiLogFragment> = {
  document: ApiLogFragmentDocument,
  fragmentName: "ApiLog",
  entityName: "ApiLog",
  __do_not_use_Data: null
}
export type ApiLogFragment = {
  __typename: "ApiLog";
  uuid: UUID;
  createdAt: ISOString;
  ipAddress: string;
  path: string;
  responseCode: Int;
  errorCode: Maybe<Int>;
  errorMessage: Maybe<string>;
  apiKey: string;
  apiVersion: Maybe<string>;
  method: ApiMethod
}

export type GetWebhookEventData = { __typename: "WebhookEventOutput"; webhookEvent: WebhookEventFragment&{ calls: Array<WebhookCallFragment> } }
export type GetWebhookEventVariables = { uuid: UUID }
export const GetWebhookEvent: Query<GetWebhookEventData, GetWebhookEventVariables, true, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetWebhookEvent($uuid: UUID!) {
  webhookEvent(uuid: $uuid) {
    webhookEvent {
      ...WebhookEvent
      calls {
        ...WebhookCall
      }
    }
  }
}
${WebhookEventFragmentDocument}
${WebhookCallFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type RetryWebhookCallData = { __typename: "RetryWebhookCallOutput"; webhookCall: WebhookCallFragment }
export type RetryWebhookCallVariables = { uuid: UUID }
export const RetryWebhookCall: Mutation<RetryWebhookCallData, RetryWebhookCallVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation RetryWebhookCall($uuid: UUID!) {
  retryWebhookCall(webhookCallUuid: $uuid) {
    webhookCall {
      ...WebhookCall
    }
  }
}
${WebhookCallFragmentDocument}
`,
  endpointName: "retryWebhookCall",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetWebhookEventsData = { __typename: "WebhookEventsOutput"; nextCursor: Maybe<string>; hasMore: boolean; data: Array<WebhookEventFragment> }
export type GetWebhookEventsVariables = { filters?: Maybe<WebhookEventsFilter>; cursor?: Maybe<string> }
export const GetWebhookEvents: Query<GetWebhookEventsData, GetWebhookEventsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetWebhookEvents($filters: WebhookEventsFilter, $cursor: String) {
  webhookEvents(page: {numberOfItems: 20, cursor: $cursor}, filters: $filters) {
    nextCursor
    hasMore
    data {
      ...WebhookEvent
    }
  }
}
${WebhookEventFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetWebhookSettingsData = { __typename: "Organization"; alertEmails: Array<EmailAddress> }
export type GetWebhookSettingsVariables = { [key: string]: never }
export const GetWebhookSettings: Query<GetWebhookSettingsData, GetWebhookSettingsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetWebhookSettings {
  myOrganization {
    alertEmails
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetWebhooksData = Array<WebhookFragment>
export type GetWebhooksVariables = { [key: string]: never }
export const GetWebhooks: Query<GetWebhooksData, GetWebhooksVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetWebhooks {
  webhooks {
    ...Webhook
  }
}
${WebhookFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteWebhookData = MutationStatus
export type DeleteWebhookVariables = { uuid: UUID }
export const DeleteWebhook: Mutation<DeleteWebhookData, DeleteWebhookVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation DeleteWebhook($uuid: UUID!) {
  deleteWebhook(uuid: $uuid)
}

`,
  endpointName: "deleteWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateWebhookData = { __typename: "CreateWebhookOutput"; webhook: WebhookFragment }
export type CreateWebhookVariables = { input: CreateWebhookInput }
export const CreateWebhook: Mutation<CreateWebhookData, CreateWebhookVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation CreateWebhook($input: CreateWebhookInput!) {
  createWebhook(input: $input) {
    webhook {
      ...Webhook
    }
  }
}
${WebhookFragmentDocument}
`,
  endpointName: "createWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateWebhookData = { __typename: "UpdateWebhookOutput"; webhook: WebhookFragment }
export type UpdateWebhookVariables = { uuid: UUID; input: UpdateWebhookInput }
export const UpdateWebhook: Mutation<UpdateWebhookData, UpdateWebhookVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateWebhook($uuid: UUID!, $input: UpdateWebhookInput!) {
  updateWebhook(uuid: $uuid, input: $input) {
    webhook {
      ...Webhook
    }
  }
}
${WebhookFragmentDocument}
`,
  endpointName: "updateWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type SendFakeWebhookEventData = { __typename: "SendFakeWebhookEventOutput"; webhookCall: WebhookCallFragment }
export type SendFakeWebhookEventVariables = { webhookUuid: UUID; eventType: string; eventDataJson: string }
export const SendFakeWebhookEvent: Mutation<SendFakeWebhookEventData, SendFakeWebhookEventVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation SendFakeWebhookEvent($webhookUuid: UUID!, $eventType: String!, $eventDataJson: String!) {
  sendFakeWebhookEvent(
    webhookUuid: $webhookUuid
    eventType: $eventType
    eventDataJson: $eventDataJson
  ) {
    webhookCall {
      ...WebhookCall
    }
  }
}
${WebhookCallFragmentDocument}
`,
  endpointName: "sendFakeWebhookEvent",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateServerKeyData = { __typename: "CreateServerKeyOutput"; serverKey: ServerKeyFragment; bearerToken: string }
export type CreateServerKeyVariables = { serverKeyInput: CreateServerKeyInput }
export const CreateServerKey: Mutation<CreateServerKeyData, CreateServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation CreateServerKey($serverKeyInput: CreateServerKeyInput!) {
  createServerKey(serverKeyInput: $serverKeyInput) {
    serverKey {
      ...ServerKey
    }
    bearerToken
  }
}
${ServerKeyFragmentDocument}
`,
  endpointName: "createServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateServerKeyData = { __typename: "UpdateServerKeyOutput"; serverKey: ServerKeyFragment }
export type UpdateServerKeyVariables = { uuid: UUID; serverKeyInput: UpdateServerKeyInput }
export const UpdateServerKey: Mutation<UpdateServerKeyData, UpdateServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateServerKey($uuid: UUID!, $serverKeyInput: UpdateServerKeyInput!) {
  updateServerKey(uuid: $uuid, serverKeyInput: $serverKeyInput) {
    serverKey {
      ...ServerKey
    }
  }
}
${ServerKeyFragmentDocument}
`,
  endpointName: "updateServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetAPIVersionsData = { __typename: "APIVersionsOutput"; versions: Array<string> }
export type GetAPIVersionsVariables = { [key: string]: never }
export const GetAPIVersions: Query<GetAPIVersionsData, GetAPIVersionsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetAPIVersions {
  apiVersions {
    versions
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetOrganizationAPIVersionData = { __typename: "Organization"; uuid: UUID; pinnedVersion: string }
export type GetOrganizationAPIVersionVariables = { [key: string]: never }
export const GetOrganizationAPIVersion: Query<GetOrganizationAPIVersionData, GetOrganizationAPIVersionVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetOrganizationAPIVersion {
  myOrganization {
    uuid
    pinnedVersion
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type UpdateOrganizationAPIVersionData = { __typename: "UpdateOrganizationOutput"; organization: { __typename: "Organization"; uuid: UUID; pinnedVersion: string } }
export type UpdateOrganizationAPIVersionVariables = { input: UpdateOrganizationInput }
export const UpdateOrganizationAPIVersion: Mutation<UpdateOrganizationAPIVersionData, UpdateOrganizationAPIVersionVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateOrganizationAPIVersion($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    organization {
      uuid
      pinnedVersion
    }
  }
}

`,
  endpointName: "updateOrganization",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type AllServerKeysData = Array<ServerKeyFragment>
export type AllServerKeysVariables = { [key: string]: never }
export const AllServerKeys: Query<AllServerKeysData, AllServerKeysVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query AllServerKeys {
  serverKeys {
    ...ServerKey
  }
}
${ServerKeyFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteServerKeyData = MutationStatus
export type DeleteServerKeyVariables = { uuid: UUID }
export const DeleteServerKey: Mutation<DeleteServerKeyData, DeleteServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation DeleteServerKey($uuid: UUID!) {
  deleteServerKey(uuid: $uuid)
}

`,
  endpointName: "deleteServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetApiLogsData = { __typename: "ApiLogsOutput"; nextCursor: Maybe<string>; hasMore: boolean; data: Array<ApiLogFragment> }
export type GetApiLogsVariables = { filters?: Maybe<ApiLogsFilter>; cursor?: Maybe<string> }
export const GetApiLogs: Query<GetApiLogsData, GetApiLogsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogs($filters: ApiLogsFilter, $cursor: String) {
  apiLogs(page: {numberOfItems: 20, cursor: $cursor}, filters: $filters) {
    nextCursor
    hasMore
    data {
      ...ApiLog
    }
  }
}
${ApiLogFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetApiLogEndpointsData = { __typename: "ApiLogEndpointsOutput"; paths: Array<string> }
export type GetApiLogEndpointsVariables = { [key: string]: never }
export const GetApiLogEndpoints: Query<GetApiLogEndpointsData, GetApiLogEndpointsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogEndpoints {
  apiLogEndpoints {
    paths
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetApiLogCodesData = { __typename: "ApiLogCodesOutput"; codes: Array<Int> }
export type GetApiLogCodesVariables = { [key: string]: never }
export const GetApiLogCodes: Query<GetApiLogCodesData, GetApiLogCodesVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogCodes {
  apiLogCodes {
    codes
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}